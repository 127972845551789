import Script from 'next/script'
import { useEffect, useState } from 'react'

declare global {
  interface Window {
    EasyChatGPT?: {
      init: (config: { handle: string }) => void
    }
  }
}

export default function EasyChatGPTWidget() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    if (isScriptLoaded && window.EasyChatGPT) {
      window.EasyChatGPT.init({ handle: 'picovn-drr' })
    }
  }, [isScriptLoaded])

  return (
    <>
      <Script
        src='https://widget.easychatgpt.io/dist/widget/main.js'
        strategy='lazyOnload'
        onLoad={() => setIsScriptLoaded(true)}
      />
    </>
  )
}
